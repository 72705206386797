import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import api from "../../../../helper/auth_interceptor";
import bin from "../../../../images/bin.png";
import navigate from "../../../../images/navigate.png";
import edit from "../../../../images/secondayEdit.png";
import "./addressDetails.modules.css";

import Modal from "react-modal";
import { getUser } from "../../../../helper/auth_helper";
import EditAddressModal from "./components/editAddressModal";

function ProjectAddressDetails({
  projectInfo,
  backendAddress,
  id,
  setProject,
  getAddresses,
}) {
  const [addressList, setAddressList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [addressId, setAddressId] = useState(null);
  const [userModalIsOpen, setUserModalIsOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [sortColumn, setSortColumn] = useState(""); // Column name for sorting
  const [editingAddress, setEditingAddress] = useState([]);
  const { t } = useTranslation();
  const handleSort = (columnName) => {
    const newSortOrder =
      columnName === sortColumn && sortOrder === "asc" ? "desc" : "asc";

    setSortOrder(newSortOrder);
    setSortColumn(columnName);

    getAddresses("", currentPage, sortColumn, sortOrder);
  };

  const deleteRow = async (addressId) => {
    const user = await getUser();
    const delApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/address/${addressId}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      await api.delete(delApi, { headers });
      toast.success(`${t('adressDeleted')}`, {
        toastId: "address deleted",
      });
      getAddresses("", currentPage, sortColumn, sortOrder);
    } catch (error) {
      console.log("Error deleting row:", error);
    }
  };

  const DeleteAllAddresses = async () => {
    const user = await getUser();
    const delApi = `${process.env.REACT_APP_API_GATEWAY_URL}/project-management/api/v1/address/project/${id}`;
    const headers = {
      Authorization: `Bearer ${user.access_token}`,
    };
    try {
      await api.delete(delApi, { headers });
      getAddresses("", currentPage, sortColumn, sortOrder);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const deleteAllRows = async () => {
    const confirmDelete = window.confirm(
      `${t('delete_popUpAlert')}`
    );
    if (confirmDelete) {
      await DeleteAllAddresses();

      setAddressList([
        {
          streetName: null,
          city: null,
          postcode: null,
          houseNo: null,
          country: null,
          bagId: null,
        },
      ]);

      setProject({
        ...projectInfo,
        addressList: [
          {
            streetName: null,
            city: null,
            postcode: null,
            houseNo: null,
            country: null,
            bagId: null,
          },
        ],
      });
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getAddresses("", page, sortColumn, sortOrder);
  };

  const handleEditClick = (index, addressId) => {
    const editedAddress = addressList[index];
    const addId = editedAddress.id;
    editedAddress.buildingId = editedAddress.projectBuildingId;
    setEditingAddress(editedAddress);

    setUserModalIsOpen(true);
    setAddressId(addId);
  };

  const submitSearchTerm = (value) => {
    getAddresses(value, currentPage);
  };

  const OpenAddressLink = (address) => {
   
    //  const address = "Mankeslaan 13 3431";
    const zoomLevel = 1;

    const queryParams = `?searchQuery=${encodeURIComponent(address.houseNo + ' ' + address.streetName + ' ' + address.postcode + ' ' + address.city )}&zoomlevel=${zoomLevel}`;

    const url = `https://bagviewer.kadaster.nl/lvbag/bag-viewer/${queryParams}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  }

  useEffect(() => {
    if (backendAddress) {
      setAddressList(backendAddress.data || []);
      setTotalPages(backendAddress.totalPages || 1);
    }
  }, [backendAddress]);

  return (
    <>
    <div className="main-form-head">
      <div className="form-head ">
        <h2 className="pageHeading ">{t('tabThreeHeader_addressDetails')}</h2>
      </div>
    </div>

      <div class="search-content">
        <div>
          <input
            className="form-control searchBar"
            type="text"
            placeholder={t('search')}
            onChange={(e) => {
              submitSearchTerm(e.target.value);
            }}
          />
        </div>
        <div className="delete-content">
        {!projectInfo.readOnly?(<button className="btn" onClick={deleteAllRows}>
            <span>{t('deleteAll_editProjectTable')}</span>
          </button>):(<button className="btn" disabled>
          <span>{t('deleteAll_editProjectTable')}</span></button>)}
        </div>
      </div>

      <div className="bulk-address-data">
        <div class="bulk-address-data-table address-table">
          <table>
            <thead>
              <tr>
              <th onClick={() => handleSort("buildingName")}>
                  {t('buildingName_editProjectTable')}
                  {sortColumn === "buildingName" && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("houseNo")}>
                {t('houseNumber_editProjectTable')}
                  {sortColumn === "houseNo" && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                <th className=" " onClick={() => handleSort("streetName")}>
                {t('streetName_editProjectTable')}
                  {sortColumn === "streetName" && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("city")}>
                {t('city_editProjectTable')}
                  {sortColumn === "city" && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("postcode")}>
                {t("postCode_editProjectTable")}
                  {sortColumn === "postcode" && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("country")}>
                {t('country_editProjectTable')}
                  {sortColumn === "country" && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                <th onClick={() => handleSort("bagId")}>
                {t('bagId_editProjectTable')}
                  {sortColumn === "houseNo" && (
                    <span>{sortOrder === "asc" ? "↑" : "↓"}</span>
                  )}
                </th>
                <th>{t('preview__editProjectTable')}</th>
                <th>{t('edit_editProjectTable')}</th>
                <th>{t('delete_editProjectTable')}</th>
              </tr>
            </thead>
            <tbody>
              {addressList.map((address, index) => (
                <tr key={index}>
                  <td>{address.buildingName}</td>
                  <td>{address.houseNo}</td>
                  <td>{address.streetName}</td>
                  <td>{address.city}</td>

                  <td>{address.postcode}</td>
                  <td>{address.country}</td>
                  <td>{address.bagId}</td>
                  <td>
                    <button className="btn btn-success preview-btn"  onClick={() => OpenAddressLink(address)} >
                    
                      <img src={navigate} alt="navigation-icon" />
                    
                    </button>
                  </td>
                  <td>
                  {!projectInfo.readOnly?(<button
                      className="btn actionBtns"
                      onClick={() => handleEditClick(index, address.id)}
                    >
                      <img src={edit} alt="edit" />
                    </button>):(<button
                      className="btn actionBtns" disabled
                    >
                      <img src={edit} alt="edit" />
                    </button>)}
                  </td>
                  <td>
                  {!projectInfo.readOnly?(<button
                      className="btn btn-light actionBtns"
                      onClick={() => deleteRow(address.id)}
                    >
                      <img src={bin} alt="navigation-icon" />
                    </button>):(<button
                      className="btn btn-light actionBtns"
                     disabled
                    >
                      <img src={bin} alt="navigation-icon" />
                    </button>)}
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal
          isOpen={userModalIsOpen}
          onRequestClose={() => setUserModalIsOpen(false)}
          style={{
            content: {
              width: "80%",
              height: "60%",
              margin: "auto",
            },
          }}
        >
          <div className="modal-content">
            <div class="modal-header">
              <div>
                <h2>{t('editPageHeader_addressDetails')}</h2>
              </div>
              <div>
                <button
                  className="closeBtn"
                  onClick={() => setUserModalIsOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="25px"
                  >
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                </button>
              </div>
            </div>
          </div>

          <EditAddressModal
            proId={id}
            addedId={addressId}
            getAddresses={getAddresses}
            closeModal={() => setUserModalIsOpen(false)}
            editingAddress={editingAddress}
          />
        </Modal>
      </div>
      <div className="edit-pagination ">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </>
  );
}

export default ProjectAddressDetails;
